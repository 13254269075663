.card {
  width: 18rem;
  height: 33rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.card img {
  margin-top: 1rem;
  height: 23rem;
}

.card-body {
  color: darkslategray;
  text-align: center;
}

.project-link {
  color: darkslategray;
  margin-right: 1.5rem;
  margin-left: 1.5rem;
  font-size: 1.3rem;
}

.project-link:hover {
  color: rgba(47, 79, 79, 0.7);
}

.project-link:active i {
  transform: translate(2px, 2px);
}

/* -------------------- RESPONSIVENESS ------------------- */
/* https://stackoverflow.com/questions/6370690/media-queries-how-to-target-desktop-tablet-and-mobile */

/* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
@media screen and (min-width: 320px) {
}

/* smartphones, Android phones, landscape iPhone */
@media screen and (min-width: 480px) {
}

/* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
@media screen and (min-width: 600px) {
  .card {
    /* width: 300px; */
    flex-grow: 50%;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}

/* tablet, landscape iPad, lo-res laptops ands desktops */
@media screen and (min-width: 801px) {
}

/* big landscape tablets, laptops, and desktops */
@media screen and (min-width: 991px) {
}

@media screen and (min-width: 994px) {
}

/* hi-res laptops and desktops */
@media screen and (min-width: 1281px) {
}
