footer {
  height: 100px;
  text-align: center;
  margin-top: 2rem;
}

footer i {
  font-size: 2rem;
  margin-top: 30px;
  color: rgba(248, 249, 250, 0.5);
  padding-bottom: 50px;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

footer i:hover {
  color: rgba(245, 245, 245, 0.2);
}

footer a:active i {
  transform: translate(2px, 2px);
}

/* -------------------- RESPONSIVENESS ------------------- */
/* https://stackoverflow.com/questions/6370690/media-queries-how-to-target-desktop-tablet-and-mobile */

/* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
@media screen and (min-width: 320px) {
}

/* smartphones, Android phones, landscape iPhone */
@media screen and (min-width: 480px) {
}

/* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
@media screen and (min-width: 600px) {
}

/* tablet, landscape iPad, lo-res laptops ands desktops */
@media screen and (min-width: 801px) {
}

/* big landscape tablets, laptops, and desktops */
@media screen and (min-width: 991px) {
}

@media screen and (min-width: 994px) {
}
