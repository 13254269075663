.smiley {
  width: 40px;
  height: 40px;
}

form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

form input {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  width: 90%;
}

form label {
  margin-top: 0.5rem;
}

.alert-message {
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: #ee6e73;
  text-align: center;
  font-style: italic;
  font-weight: bold;
}

#message-input {
  height: 100px;
}

form .btn-primary {
  background-color: #2196f3;
  color: whitesmoke;
}

form .btn-primary:hover {
  background-color: rgba(33, 150, 143, 0.8);
  border: 1px solid rgba(33, 150, 143, 0.8);
}

form .btn-primary:active {
  transform: translate(2px, 2px);
}

.button-div {
  text-align: center;
}

.contact-links {
  display: flex;
  flex-direction: column;
}

/* -------------------- RESPONSIVENESS ------------------- */
/* https://stackoverflow.com/questions/6370690/media-queries-how-to-target-desktop-tablet-and-mobile */

/* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
@media screen and (min-width: 320px) {
}

/* smartphones, Android phones, landscape iPhone */
@media screen and (min-width: 480px) {
}

/* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
@media screen and (min-width: 600px) {
  form input {
    width: 75vw;
  }
}

/* tablet, landscape iPad, lo-res laptops ands desktops */
@media screen and (min-width: 801px) {
  form input {
    width: 60vw;
  }

  .contact-links {
    flex-direction: row;
  }

  .contact-links a {
    padding: 1rem 1rem;
  }
}

/* big landscape tablets, laptops, and desktops */
@media screen and (min-width: 991px) {
  form input {
    width: 50vw;
  }
}

@media screen and (min-width: 994px) {
}

/* hi-res laptops and desktops */
@media screen and (min-width: 1281px) {
}
