.container {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.card-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.yellow-text {
  color: #ffeb3b;
}

.blue-text {
  color: #2196f3;
}

.page-title {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.bottom-bracket {
  margin-top: 1.5rem;
}

/* -------------------- RESPONSIVENESS ------------------- */
/* https://stackoverflow.com/questions/6370690/media-queries-how-to-target-desktop-tablet-and-mobile */

/* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
@media screen and (min-width: 320px) {
}

/* smartphones, Android phones, landscape iPhone */
@media screen and (min-width: 480px) {
}

/* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
@media screen and (min-width: 600px) {
  .card-container {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

/* tablet, landscape iPad, lo-res laptops ands desktops */
@media screen and (min-width: 801px) {
}

/* big landscape tablets, laptops, and desktops */
@media screen and (min-width: 991px) {
}

@media screen and (min-width: 994px) {
}

/* hi-res laptops and desktops */
@media screen and (min-width: 1281px) {
}
